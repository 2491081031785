<template>
  <div class="form-compact form-compact-campaign-rule">

    <div class="form-compact-header">
      <v-system-bar dark color="primary">
        <v-icon class="mr-1">{{ $t('CAMPAIGN_RULE.ICON') }}</v-icon>

        <small><strong class="text-uppercase">{{ $t(props ? 'CAMPAIGN_RULE.FORM_TITLE_UPDATE' : 'CAMPAIGN_RULE.FORM_TITLE_NEW') }}</strong></small>

        <v-spacer/>
        <v-icon
          small
          class="clickable"
          @click="$emit('close')"
        >
          mdi-close
        </v-icon>
      </v-system-bar>

      <v-toolbar
        color="transparent"
        flat
        height="66px"
      >
        <v-toolbar-title class="primary--text">
          {{ $tc('CAMPAIGN_RULE.FORM_TITLE_RULE') }}
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip bottom transition="none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              depressed
              color="success"
              @click="saveItem"
            >
            <!-- :disabled="" -->
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </template>
          {{ $t('COMMON.APPLY') }}
        </v-tooltip>
      </v-toolbar>
      <div class="toolbar-separator primary ml-4 mr-4"/>
    </div>

    <div class="form-compact-content">


    </div>

  </div>
</template>


<style>

</style>

<script>

// import Loading from '@/components/Loading'
// import StatusLabel from '@/components/StatusLabel'

export default {
  name: 'FormCampaingRuleCompact',
  data: () => ({
    valid: false,
    loading: false,
    events: [],
    actions: [],
    limit: 0,
  }),
  components: {
    // Loading,
    // StatusLabel,
  },
  props: {
    ids: {
      type: Array,
      default: () => ([])
    },
    props: {
      type: [Number, Object, Boolean],
      default: false
    },
  },
  computed: {
    hideCampaign () {
      return !this.props.campaign_id
    }
  },
  watch: {
    campaign () {
    }
  },
  created () {
  },
  methods: {

    saveItem () {
      this.$emit(!this.props ? 'create' : 'update', {
        events: this.$clone(this.events),
        actions: this.$clone(this.actions),
        limit: +this.limit,
      })
    }

  }
}



</script>
